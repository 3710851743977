<template>
  <div class="businessLedgerCollect">
    <div>
      <span v-if="myBrand" class="mr_20">{{myBrand.name}}</span>
      <span v-else-if="myComp" class="mr_20">{{myComp.name}}</span>
      <span v-if="!myBrand && !myComp && myShop" class="mr_20">{{myShop.name}}</span>
    </div>
    <div class="search-bar">
      <el-form
        ref="searchRef"
        :model="searchForm"
        label-width="100px"
        size="small"
      >
        <div class="search-group-wrap flex">
          <!-- <el-row> -->
            <!-- <el-col :span="6"> -->
              <el-form-item label="商家"
                  v-if="myBrand || myComp">
                <el-select
                  style="width: 100%"
                  v-model="searchForm.businessId"
                  :remote-method="businessRemoteMethod"
                  :loading="businessLoading"
                  placeholder="选择商家"
                  clearable
                  filterable
                  remote
                  @focus="businessRemoteMethod()"
                  @change="getLedgerSummaryDetail"
                >
                  <el-option
                    v-for="item in businessList"
                    :key="item.id"
                    :label="item.full_name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            <!-- </el-col> -->
            <!-- <el-col :span="6"> -->
              <el-form-item label="日期">
                <el-date-picker
                  style="width: 100%"
                  v-model="collect_Time"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="setDateRange"
                  @change="getLedgerSummaryDetail"
                >
                </el-date-picker>
              </el-form-item>
            <!-- </el-col> -->
          <!-- </el-row> -->
          <div class="ml_20">
            <el-button type="primary" size="small"
                @click="getLedgerSummaryDetail">搜索</el-button>
          </div>
        </div>
      </el-form>
    </div>

    
    <div class="collect-table">
      <div class="title flex">
        <h3>还款数据</h3>
        <span class="ml_20">按还款时间查询</span>
      </div>
      <el-table
        :data="tableData"
        header-cell-class-name="table_header"
        border
      >
        <el-table-column width="140px" prop="repayment_amount" label="已还总金额" />
        <el-table-column width="140px" prop="repayment_principal" label="已还本金" />
        <el-table-column width="140px" prop="repayment_interest" label="商家管理费" 
          v-if="has_business_interest" />
        <el-table-column width="140px" prop="repayment_interest_business_payer" label="商家贴息"
          v-if="has_business_payer" />
        <el-table-column width="140px" prop="repayment_refund_business_income" label="退费手续费" />
      </el-table>
      <el-table
        :data="tableData"
        header-cell-class-name="table_header"
        border
      >
        <el-table-column width="140px" prop="unpaid_amount" label="未还总金额" />
        <el-table-column width="140px" prop="unpaid_principal" label="本金" />
        <el-table-column width="140px" prop="unpaid_interest" label="商家管理费"
          v-if="has_business_interest" />
        <el-table-column width="140px" prop="unpaid_interest_business_payer" label="商家贴息"
          v-if="has_business_payer" />
      </el-table>
    </div>

    <div class="collect-table">
      <div class="title flex">
        <h3>分账数据</h3>
        <span class="ml_20">按分账时间查询</span>
      </div>
      <el-table
        :data="tableData"
        header-cell-class-name="table_header"
        border
      >
        <el-table-column width="140px" prop="ledger_amount" label="总金额" />
        <el-table-column width="140px" prop="ledger_principal" label="本金" />
        <el-table-column width="140px" prop="ledger_interest" label="商家管理费" 
          v-if="has_business_interest" />
        <el-table-column width="140px" prop="ledger_interest_business_payer" label="商家贴息" 
          v-if="has_business_payer" />
        <el-table-column width="140px" prop="ledger_refund_business_income" label="退款手续费" />
      </el-table>
      
    </div>
  </div>
</template>

<script>
import {ledgerSummaryDetail} from '@/api/collect.js'
import {suggestBusinessList} from '@/api/business.js'
import { getMyBrand, getMyComp, getMyShop } from '@/utils/index.js'

export default {
  name: "businessLedgerCollect",

  data() {
    return {
      myBrand: getMyBrand(),
      myComp: getMyComp(),
      myShop: getMyShop(),
      
      searchForm: {
        page: 1,
        per_page: 10,
        seniorId: '',
        entityId: '',
        businessId: '',
      },
      loading: false,
      total: 0,
      tableData: [],

      has_business_payer: false, // 商家贴息
      has_business_interest: false, // 商家管理费

      // repayment_interest: false,
      // repayment_interest_business_payer: false,
      // has_ledger_interest: false,
      // has_ledger_interest_business_payer: false,

      businessLoading: false,
      businessList: [],
      baseBusinessList: [],

      setDateRange: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      collect_Time: [],
    };
  },

  async mounted() {
    this.initData();
    this.baseBusinessList = await this.getSelectBusiness()
  },

  methods: {
    initData() {
      this.getLedgerSummaryDetail();
    },

    async getLedgerSummaryDetail() {
      this.searchForm.seniorId = ''
      this.searchForm.entityId = ''
      if(!this.myBrand && !this.myComp && this.myShop){
        this.searchForm.businessId = this.myShop.id
      }
      if(!this.searchForm.businessId){
        if(this.myBrand){
          this.searchForm.seniorId = this.myBrand.id
        }else if(this.myComp){
          this.searchForm.entityId = this.myComp.id
        }
      }

      if (this.collect_Time && this.collect_Time.length == 2) {
        this.searchForm.startTime = this.collect_Time[0] + " " + "00:00:00";
        this.searchForm.endTime   = this.collect_Time[1] + " " + "23:59:59";
      } else {
        this.searchForm.startTime = "";
        this.searchForm.endTime   = "";
      }
      
      const { code, data } = await ledgerSummaryDetail(this.searchForm);
      this.loading = true;
      if (code == 200) {
        this.loading = false;
        let has_business_payer = false
        let has_business_interest = false
        if(data[0]){
          if(Number(data[0]['repayment_interest']) > 0 ||
            Number(data[0]['unpaid_interest']) > 0 ||
            Number(data[0]['ledger_interest']) > 0){
            has_business_interest = true
          }
          if(Number(data[0]['repayment_interest_business_payer']) > 0 ||
            Number(data[0]['unpaid_interest_business_payer']) > 0 ||
            Number(data[0]['ledger_interest_business_payer']) > 0){
            has_business_payer = true
          }
        }
        this.has_business_interest = has_business_interest
        this.has_business_payer = has_business_payer

        data.map(el=>{
          if(Number(el['repayment_interest_business_payer']) > 0){
            el['repayment_interest_business_payer'] = '-'+el['repayment_interest_business_payer']
          }
          if(Number(el['unpaid_interest_business_payer']) > 0){
            el['unpaid_interest_business_payer'] = '-'+el['unpaid_interest_business_payer']
          }
          if(Number(el['ledger_interest_business_payer']) > 0){
            el['ledger_interest_business_payer'] = '-'+el['ledger_interest_business_payer']
          }
        })
        this.tableData = data;
      } else {
        this.loading = false;
      }
    },

    businessRemoteMethod(query) {
      if (query) {
        this.businessLoading = true;
        setTimeout(async () => {
          this.businessLoading = false;
          this.businessList = await this.getSelectBusiness(query)
        }, 200);
      } else {
        this.businessList = this.baseBusinessList;
      }
    },
    async getSelectBusiness(keyword) {
      const {code, data} = await suggestBusinessList({
        keyword: keyword || ""
      })
      if(code == 200){
        return data
      }
      return []
    },
  },
};
</script>

<style lang="scss" scoped>
.businessLedgerCollect {
  .collect-table {
    /deep/ .table_header {
      background-color: #f8f8f9 !important;
      color: #515a6e !important;
    }
    .title{
      align-items: baseline;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}
</style>